
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import AgingSummaryReport from "@/views/pages/Reports/AgingSummanyReport.vue";
import { loadReviewDate } from "@/api/patient.api";
import { loadClaimReviewDate } from "@/api/claim.api";
import { getDashboardStatistics } from "@/api/dashboard.api";
import useFilters from "@/modules/common/useFilters";
import moment from "moment";
import { EncounterStatusList, ClaimStatusList, ReviewType } from "@/store/enums/StoreEnums";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface StatusCode {
  code: string;
  description: string;
}

export interface StatisticsData {
  count: number;
  amount: number;
}

export interface Data {
  series?: any;
  chartOptions?: any;
  home: any;
  reviewList: any[];
  claimReviewList : any[];
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  IsAdvancedOrderBy: boolean;
  claimPagination: Pagination; 
  claimOrderBy: string;
  claimIsDecr: boolean;
  claimIsAdvancedOrderBy: boolean;
  dict: {
    encounterStatusCodes: StatusCode[];
    claimStatusCodes: StatusCode[];
  };
  statistics: {
    patientBalanceDue: number;
    insuranceBalanceDue: number;
    needAttention: StatisticsData;
    readyToBill: StatisticsData;
    billPatient: StatisticsData;
    onHold: StatisticsData;
    submittedClaim: StatisticsData;
    unSubmittedClaim: StatisticsData;
    patientPayments: number;
    insurancePayments: number;
  }
}
export default defineComponent({
  name: "HomePage",
  components: { PaginationUi, AgingSummaryReport },
  setup() {
    const router = useRouter();
    const defaultStatisticData = { count: 0, amount: 0 };

    let data = reactive<Data>({
      home: {},
      reviewList: [],
      claimReviewList : [],
      orderBy: "reviewDate",
      IsAdvancedOrderBy: false,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      claimOrderBy: "reviewDate",
      claimIsAdvancedOrderBy: false,
      claimIsDecr: false,
      claimPagination : {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      dict: {
        encounterStatusCodes: [],
        claimStatusCodes: [],
      },
      statistics: {
        patientBalanceDue: 0,
        insuranceBalanceDue: 0,
        needAttention: defaultStatisticData,
        readyToBill: defaultStatisticData,
        billPatient: defaultStatisticData,
        onHold: defaultStatisticData,
        submittedClaim: defaultStatisticData,
        unSubmittedClaim: defaultStatisticData,
        patientPayments: 0,
        insurancePayments: 0,
      }
    });

    const { orderList } = useFilters();
    orderList.value = [
      {
        name: "Patient",
        key: "name",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        
      },
      {
        name: "Location",
        key: "location",
        isFilter: false,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Review Date",
        key: "reviewDate",
        isFilter: true,
        keyword: null,
        orderProperty: "reviewDate",
      },
    ];

    const claimOrderList = orderList;    

    onMounted(async () => {
      setCurrentPageTitle("Home Page");
      const store = useStore();

      data.dict.encounterStatusCodes = store.getters.allEncounterStatuses;
      data.dict.claimStatusCodes = store.getters.allClaimStatuses;

      await getAll();
    });

    function getEncounterStatusHeader(code) {
      if (data.dict.encounterStatusCodes) {
        const status = data.dict.encounterStatusCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    function getClaimStatusHeader(code) {
      if (data.dict.claimStatusCodes) {
        const status = data.dict.claimStatusCodes.find((item) => {
          return item.code == code;
        });
        if (status) {
          return status.description;
        }
      }
      return code;
    }

    function selectItem(item, type) {
      if (type == ReviewType.Patient) {
        router.push({ path: "patient/" + item.id + "/" });
      }
      else {
        router.push({ path: "claim/" + item.id + "/claimSummary" });
      }
    }

    async function refresh() {
      await getAllPatientReviewList();
      await getAllClaimReviewList();
    }

    async function getAll() {
      await getAllPatientReviewList();
      await getAllClaimReviewList();

      let statRequest = {
        startDate: moment(new Date(getNow().getFullYear(), 0, 1)).format("MM/DD/yyyy"),
        endDate: moment(new Date(getNow().getFullYear(), 11, 31)).format("MM/DD/yyyy"),
      };

      const stats = await getDashboardStatistics(statRequest);
      data.statistics = stats;
    }

    async function getAllPatientReviewList() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }

      let request = {
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        maxDate: new Date(),
      };

      const patientReviewData = await loadReviewDate(request);
      data.reviewList = patientReviewData.data;
      data.pagination.currentPage = patientReviewData.currentPage;
      data.pagination.totalPages = patientReviewData.totalPages;
      data.pagination.totalCount = patientReviewData.totalCount;
      data.pagination.pageSize = patientReviewData.pageSize;
    }
    
    async function getAllClaimReviewList() {
      let order = data.claimOrderBy;
      if (data.claimIsDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.claimIsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        pageNumber: data.claimPagination.currentPage,
        pageSize: data.claimPagination.pageSize,
        maxDate: new Date(),
      };

      const claimReviewData = await loadClaimReviewDate(request);
      
      claimReviewData.data.forEach((item: any) => {
        item.reviewDate = new Date(item.reviewDate);
      });

      data.claimReviewList = claimReviewData.data;
      data.claimPagination.currentPage = claimReviewData.currentPage;
      data.claimPagination.totalPages = claimReviewData.totalPages;
      data.claimPagination.totalCount = claimReviewData.totalCount;
      data.claimPagination.pageSize = claimReviewData.pageSize;
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAllPatientReviewList();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAllPatientReviewList();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function selectFilter(header) {
      if (!header.key) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await getAllPatientReviewList();
    }

    async function claimPageChanged(page) {
      data.claimPagination.currentPage = page;
      await getAllClaimReviewList();
    }

    async function claimPageSizeChanged(pageSize) {
      data.claimPagination.pageSize = pageSize;
      data.claimPagination.currentPage = 1;
      await getAllClaimReviewList();
    }

    function getClaimSortInfo(key) {
      if (data.claimOrderBy == key) return true;
      return false;
    }


    async function selectClaimFilter(header) {
      if (!header.key) {
        return;
      }
      if (data.claimOrderBy == header.key) {
        data.claimIsDecr = !data.claimIsDecr;
      } else {
        data.claimIsDecr = false;
      }
      console.log(header);
      data.claimOrderBy = header.key;
      data.claimIsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await getAllClaimReviewList();
    }

    function goToServiceManagement(filter) {
      router.push({ path: "/serviceManagement" , query: { status: filter }  });
    }

    function goToClaimManagement(filter) {
      router.push({ path: "/ClaimManagement" , query: { status: filter } });
    }

    return {
      orderList,
      claimOrderList,
      getSortInfo,
      selectFilter,
      getClaimSortInfo,
      selectClaimFilter,
      getEncounterStatusHeader,
      getClaimStatusHeader,
      selectItem,
      pageChanged,
      pageSizeChanged,
      claimPageChanged,
      claimPageSizeChanged,
      refresh,
      data,
      goToServiceManagement,
      goToClaimManagement,
      EncounterStatusList,
      ClaimStatusList,
      ReviewType,
    };
  },
});
