
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import { getPanel } from "@/api/encounter.api";
import { adjustmentReport, getARAgingSummary } from "@/api/reports.api";
import { useStore } from "vuex";
import { searchByName } from "@/api/patient.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}

export interface Data {
  model: any;
}
export default defineComponent({
  name: "AgingSummaryReport",
  props: ["patientId"],
  components: {},
  setup(props) {
    const store = useStore();
    const router = useRouter();

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      model: null,
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      const res = await getARAgingSummary({});
      data.model = res;
    });

    return {
      organizationId,
      data,
    };
  },
});
