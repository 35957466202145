import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/dashboard"

export async function getDashboardStatistics(request) {
  const queryString = `?${new URLSearchParams(request)}`;
  return await ApiService.get(`${url}/get`, queryString)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}
